import axios from "axios";

// const BASE_URL = "http://localhost:5000/api-pkindev-platform/asia-southeast2/pkindev";
// const BASE_URL =  "https://asia-southeast2-api-pkindev-platform.cloudfunctions.net";




const BASE_URL =  "https://asia-southeast2-api-pkindev-platform.cloudfunctions.net/pkindev";

let HEADER = {
  "Content-Type": "application/json",
  withCredentials: true,
};

export class Provider {
  api(options) {
    return new Promise(async (resolve, reject) => {
      const { type, url, data, token } = options;
      let dataRes;

      // อัพเดท headers หากมี token
      const headers = token ? { ...HEADER, "x-has-accesstoken": token } : HEADER;

      const instanceMain1 = axios.create({
        baseURL: BASE_URL,
        headers,
        transformResponse: [
          function (data) {
            try {
              const _data = JSON.parse(data);
              if (_data === null) return _data;
              if (_data.msg !== undefined && _data.msg === "ข้อมูลการ Authen ไม่ถูกต้อง") {
                window.location = `${BASE_URL}/403`;
              }
              return _data;
            } catch (error) {
              return data; // คืนค่าข้อมูลเดิมหากการแปลงล้มเหลว
            }
          },
        ],
      });

      try {
        switch (type) {
          case "GET":
            dataRes = await instanceMain1.get(url, { params: data });
            break;
          case "SHOW":
            dataRes = await instanceMain1.get(url);
            break;
          case "POST":
            dataRes = await instanceMain1.post(url, data);
            break;
          case "PUT":
            dataRes = await instanceMain1.put(url, data);
            break;
          case "DELETE":
            dataRes = await instanceMain1.delete(url, { params: data });
            break;
          default:
            throw new Error("Unsupported request type");
        }
        resolve(dataRes.data);
      } catch (error) {
        reject(error);
      }
    });
  }
}
