import React, { useState, useEffect, useRef } from "react";
import {
  Modal,
  Button,
  Col,
  Row,
  Form,
  Container,
  Card,
  Image,
} from "react-bootstrap";
import ReactToPrint from "react-to-print";
import { useParams, useHistory } from "react-router-dom";
import Chip from "@material-ui/core/Chip";
import Icon from "@material-ui/core/Icon";
import styled from "styled-components";
import QRCode from "qrcode.react";
import Barcode from "react-barcode";
import { toAbsoluteUrl } from "../../helpers/Utils";
import SVG from "react-inlinesvg";
import $ from "jquery";
import SpinnerLoading from "../../components/Loading/SpinnerLoading";
import THBText from "thai-baht-text";
import {
  provinceAddress,
  districtAddress,
  subdistrictAddress,
  getProvince1,
  getDistrict1,
  getSubdistrict1,
  getCompanyPkindev,
  getCustomerInvoice,
  getNameTitle,
  getWidthHoldingTax,
  getBankPayment,
} from "../../redux/actions";
import { connect } from "react-redux";
import ModalPayment from "./ModalPayment";
import ModalPolicy, { PolicyPage } from "./ModalPolicy";
import { Checkbox } from "@material-ui/core";

const StyledWrapper = styled.div`
  display: flex;
  padding: 10px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;

  input[type="checkbox"] {
    width: 20px;
    height: 20px;
  }
  @media screen {
    .policy_page {
      display: none;
    }
  }
  /* @media (max-width: 992px) {
    .crop_content {
      transform: scale(0.8);
      transform-origin: 0 0;
    }
    @media (max-width: 425px) {
      .crop_content {
        transform: scale(0.4);
      }
    }
  } */
`;

const dataCompany = {
  name_company: "",
  name_company_en: "",
  name_branch: "",
  head_office: 0,
  address: "",
  address_en: "",
  address2: "",
  address2_en: "",
  id_country: 66,
  id_province: "",
  id_district: "",
  id_subdistrict: "",
  postal_code: "",
  website: "",
  phone_no: "",
  ext_no: "",
  fax_no: "",
  email: "",
  id_tax: "",
  icon_company_url: "",
  textlogo_company_url: "",
  authsignature_company_url: "",
  stamp_company_url: "",
  cash: { check: 0 },
  credit: { public_key: "", secret_key: "", check: 0, enable: 0, status: "" },
  paypal: {
    account: "",
    client_id: "",
    secret: "",
    check: 0,
    enable: 0,
    status: "",
  },
  bill: {
    check: 0,
    enable: 0,
    bank: {
      suffix_key: "",
      prefix_ref1: "",
      prefix_ref2: "",
      check: 0,
      bank_detail: [],
    },
    lotus: { check: 0 },
  },
  transfer: {
    id_bank: "",
    branch_name: "",
    account_name: "",
    account_type: "",
    account_number: "",
    photo_upload: { name: "Upload", url: null },
    check: 0,
    enable: 0,
  },
};
const dataInvoice = {
  name_project: "",
  quotation_no: "",
  product_detail: [
    {
      name_product: "",
      price_per_unit: "",
      qty: "",
      unit: "",
      discount: "",
      discount_type: "",
      description: "",
      total: "",
    },
  ],
  subtotal: "",
  discount: "",
  discount_type: "",
  vat: "",
  total: "",
  total_amount: "",
  issue_date: "",
  due_date: "",
  remark: "",
  barcode_tesco: "",
  idWithHoldingTax: "",
  holding_tax_price: "",
  status: "",
  purchase_order_no: "",
};
const dataCustomer = {
  customer_code: "",
  name_company: "",
  detail_type: "",
  head_office: 0,
  name_branch: "",
  name_personal: "",
  id_name_title: "",
  name_organization: "",
  name_staff: "",
  address: "",
  address2: "",
  id_country: 66,
  id_province: "",
  id_district: "",
  id_subdistrict: "",
  postal_code: "",
  id_tax: "",
  id_passport: "",
  phone_no: "",
  fax_no: "",
  email: "",
  privacy_policy: "",
  privacy_policy_issue_date: "",
  refundable_policy: "",
  refundable_policy_issue_date: "",
  terms_conditions: "",
  terms_conditions_issue_date: "",
};

const BillingNote = (props) => {
  const { id_company, id_invoice } = useParams();
  const ref = useRef();
  const history = useHistory();
  const [dataInfoCompany, setdataInfoCompany] = useState(dataCompany);
  const [dataInfoInvoice, setdataInfoInvoice] = useState(dataInvoice);
  const [dataInfoCustomer, setdataInfoCustomer] = useState(dataCustomer);
  const [modalShowPolicy, setmodalShowPolicy] = useState(false);
  const [modalShowPayment, setmodalShowPayment] = useState(false);
  const [policy_subject, setpolicy_subject] = useState("");
  const [policy_text, setpolicy_text] = useState({ th: "", en: "" });
  const [policy_content, setpolicy_content] = useState({ text: "", date: "" });
  const [agreePay, setagreePay] = useState(false);

  useEffect(() => {
    // console.log(id_company, "/", id_invoice);
    props.getCompanyPkindev({ id_company: id_company });
    props.provinceAddress({ lang: "both" });
    props.getProvince1({ lang: "both" });
    props.getCustomerInvoice({ invoice_no: id_invoice });
    props.getNameTitle();
    props.getWidthHoldingTax();
    props.getBankPayment();
  }, []);

  useEffect(() => {
    if (props.companyPkindev.length != 0) {
      let company = props.companyPkindev.find(
        (doc) => doc.id_company == id_company
      );
      if (typeof company == "undefined") {
        history.push("/");
        window.location.reload();
      }
      setdataInfoCompany(company);
      props.districtAddress({
        lang: "both",
        id: company.id_province,
      });
      props.subdistrictAddress({
        lang: "both",
        id: company.id_district,
      });
    }
  }, [props.companyPkindev]);

  useEffect(() => {
    // setTimeout(() => {
    //   if (
    //     props.customerINvoice.length == 0 ||
    //     props.companyPkindev.length == 0
    //   ) {
    //     history.push("/");
    //     window.location.reload();
    //   }
    // }, 10000);
    if (props.customerINvoice.length != 0) {
      setdataInfoInvoice(props.customerINvoice[0]);
      setdataInfoCustomer(props.customerINvoice[1]);
      let checkAmountTotal = dataInfoCompany;
      if (typeof props.customerINvoice[0].barcode_tesco == "undefined")
        checkAmountTotal = {
          ...checkAmountTotal,
          bill: { ...checkAmountTotal.bill, lotus: { check: false } },
        };
      if (
        parseFloat(props.customerINvoice[0].total_amount).toFixed(2) > 150000
      ) {
        checkAmountTotal = {
          ...checkAmountTotal,
          credit: { ...checkAmountTotal.credit, check: false, enable: false },
        };
        if (
          parseFloat(props.customerINvoice[0].total_amount).toFixed(2) > 500000
        )
          checkAmountTotal = {
            ...checkAmountTotal,
            paypal: { ...checkAmountTotal.paypal, check: false, enable: false },
          };
      }
      setdataInfoCompany(checkAmountTotal);
      props.getDistrict1({
        lang: "both",
        id: props.customerINvoice[1]
          ? props.customerINvoice[1].id_province
          : "",
      });
      props.getSubdistrict1({
        lang: "both",
        id: props.customerINvoice[1]
          ? props.customerINvoice[1].id_district
          : "",
      });
    }
  }, [props.customerINvoice]);

  const numberWithCommas = (x) => {
    return parseFloat(x)
      .toFixed(2)
      .toString()
      .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  };

  const set_date = (data, joinwith) => {
    let date = new Date();
    if (data != "") date = new Date(data);
    const time1 = [
      Intl.DateTimeFormat("en-US", { day: "2-digit" }).format(date),
      Intl.DateTimeFormat("en-US", { month: "2-digit" }).format(date),
      Intl.DateTimeFormat("en-US", { year: "numeric" }).format(date),
    ];
    return time1.join(joinwith ? joinwith : "-");
  };

  const set_time = (data) => {
    let date = new Date();
    if (data != "") date = new Date(data);
    return date.getTime();
  };

  const formatNumBank = (data) => {
    return `${data.slice(0, 3)}-${data.slice(3, 4)}-${data.slice(
      4,
      9
    )}-${data.slice(9, 10)}`;
  };

  return (
    //console.log("dataInfoInvoice ::",dataInfoInvoice),
    <>
      {/* <Image src={dataInfoCompany.icon_company_url} height="80" /> */}
      <Image src="/media/PKINDEV PLATFORM/LOGO/INVOIZE_LOGO.png" height="80" />
      <StyledWrapper>
        <Card style={{ overflow: "auto", maxHeight: "80%", maxWidth: "80%" }}>
          <Card.Body ref={ref}>
            <div className="crop_content" style={{ minWidth: "1100px" }}>
              <Row style={{ minWidth: "900px" }}>
                <Col xs={5} className="pr-0">
                  <p
                    style={{
                      marginTop: "25px",
                      fontSize: "12.5px",
                      whiteSpace: "nowrap",
                      color: "black",
                    }}
                  >
                    {dataInfoCompany.name_company_en.toUpperCase()}
                    <br />
                    {dataInfoCompany.name_company}
                    <br />
                    {`${dataInfoCompany.address_en}, ${
                      props.selectSubDistrict.length != 0 &&
                      dataInfoCompany.id_subdistrict != ""
                        ? props.selectSubDistrict.find(
                            (doc) => doc.value == dataInfoCompany.id_subdistrict
                          ).label1
                        : null
                    }, ${
                      props.selectDistrict.length != 0 &&
                      dataInfoCompany.id_district != ""
                        ? props.selectDistrict
                            .find(
                              (doc) => doc.value == dataInfoCompany.id_district
                            )
                            .label1.split("Amphoe")[1]
                        : null
                    }, ${
                      props.selectProvince.length != 0 &&
                      dataInfoCompany.id_province != ""
                        ? props.selectProvince.find(
                            (doc) => doc.value == dataInfoCompany.id_province
                          ).label1
                        : null
                    }  Thailand ${dataInfoCompany.postal_code} `}
                    <br />
                    {`${dataInfoCompany.address} ตำบล${
                      props.selectSubDistrict.length != 0 &&
                      dataInfoCompany.id_subdistrict != ""
                        ? props.selectSubDistrict.find(
                            (doc) => doc.value == dataInfoCompany.id_subdistrict
                          ).label2
                        : null
                    } ${
                      props.selectDistrict.length != 0 &&
                      dataInfoCompany.id_district != ""
                        ? props.selectDistrict.find(
                            (doc) => doc.value == dataInfoCompany.id_district
                          ).label2
                        : null
                    } จังหวัด${
                      props.selectProvince.length != 0 &&
                      dataInfoCompany.id_province != ""
                        ? props.selectProvince.find(
                            (doc) => doc.value == dataInfoCompany.id_province
                          ).label2
                        : null
                    } ${dataInfoCompany.postal_code}`}
                    <br />
                    {`Tel : ${dataInfoCompany.phone_no} Fax : ${dataInfoCompany.fax_no}`}
                    <br />
                    {`เลขประจําตัวผู้เสียภาษีอากร : ${
                      dataInfoCompany.id_tax
                    } สาขาประกอบการ ${
                      dataInfoCompany.head_office
                        ? "สํานักงานใหญ่"
                        : dataInfoCompany.name_branch
                    }`}
                    <br />
                    {`TAX ID : ${dataInfoCompany.id_tax} Branch No. : ${
                      dataInfoCompany.head_office
                        ? "Head Office"
                        : dataInfoCompany.name_branch
                    }`}
                  </p>
                </Col>
                <Col xs={2} className="p-0">
                  <h4
                    style={{
                      whiteSpace: "nowrap",
                      marginLeft: "-15px",
                      marginBottom: "15px",
                      color: "black",
                    }}
                    className="text-center"
                  >
                    BILLING NOTE / INVOICE{" "}
                    {dataInfoInvoice.type && dataInfoInvoice.type == "ABB"
                      ? "(ABB)"
                      : ""}
                    <br />
                    &nbsp;&nbsp;&nbsp;&nbsp;ใบวางบิล / ใบแจ้งหนี้
                  </h4>
                </Col>
                <Col xs={5}>
                  <div className="text-right mr-4">
                    <Image
                      src={dataInfoCompany.icon_company_url}
                      style={{ maxWidth: "100%", maxHeight: "80px" }}
                    />
                    <Image
                      src={dataInfoCompany.textlogo_company_url}
                      style={{ maxWidth: "45%", maxHeight: "100px" }}
                    />
                  </div>
                  <Row>
                    <Col xs={6}></Col>
                    <Col xs={6}>
                      <h4
                        class="pl-4 pr-4 pb-2 pt-2 text-center mt-2"
                        style={{
                          paddingLeft: "50px",
                          border: "1px solid black",
                          color: "black",
                        }}
                      >
                        ต้นฉบับ/ Original
                      </h4>
                    </Col>
                  </Row>
                </Col>
              </Row>
              <Row style={{ minWidth: "900px" }}>
                <Col xs={5} className="pr-0">
                  <div
                    className="pl-3 pr-3 pb-1 pt-1"
                    style={{
                      border: "1px solid black",
                      borderRadius: "10px",
                    }}
                  >
                    <p
                      className="m-1"
                      style={{ fontSize: "12.5px", color: "black" }}
                    >
                      รหัสลูกค้า/CUSTOMER NO. :&nbsp;
                      <b>
                        {dataInfoCompany.bill.bank.prefix_ref1}
                        {dataInfoInvoice.type && dataInfoInvoice.type == "ABB"
                          ? dataInfoInvoice.customer_code
                          : dataInfoCustomer.customer_code}
                      </b>
                    </p>
                    <p
                      className="m-1"
                      style={{ fontSize: "12.5px", color: "black" }}
                    >
                      ชื่อลูกค้า/CUSTOMER NAME :&nbsp;
                      <b>
                        {dataInfoInvoice.type && dataInfoInvoice.type == "ABB"
                          ? dataInfoInvoice.customer_name
                          : dataInfoCustomer.name_company != ""
                          ? dataInfoCustomer.name_company
                          : dataInfoCustomer.name_personal != ""
                          ? `${
                              props.nameTitle.length != 0 &&
                              dataInfoCustomer.id_name_title != ""
                                ? props.nameTitle.find(
                                    (doc) =>
                                      doc.value ==
                                      dataInfoCustomer.id_name_title
                                  ).label2
                                : null
                            } ${dataInfoCustomer.name_personal}`
                          : dataInfoCustomer.name_organization}
                      </b>
                    </p>
                    {dataInfoInvoice.type &&
                    dataInfoInvoice.type != "ABB" &&
                    dataInfoCustomer.detail_type != "personal" ? (
                      <p
                        className="m-1"
                        style={{ fontSize: "12.5px", color: "black" }}
                      >
                        สาขา/BRANCH NO. :&nbsp;
                        <b>
                          {dataInfoCustomer.head_office
                            ? "สำนักงานใหญ่"
                            : dataInfoCustomer.name_branch}
                        </b>
                      </p>
                    ) : null}
                    {dataInfoInvoice.type && dataInfoInvoice.type != "ABB" ? (
                      <p
                        className="m-1"
                        style={{ fontSize: "12.5px", color: "black" }}
                      >
                        ที่อยู่/ADDRESS :&nbsp;
                        <b>
                          {`${dataInfoCustomer.address} ตำบล${
                            props.selectSubDistrict1.length != 0 &&
                            dataInfoCustomer.id_subdistrict != ""
                              ? props.selectSubDistrict1.find(
                                  (doc) =>
                                    doc.value == dataInfoCustomer.id_subdistrict
                                ).label2
                              : null
                          } ${
                            props.selectDistrict1.length != 0 &&
                            dataInfoCustomer.id_district != ""
                              ? props.selectDistrict1.find(
                                  (doc) =>
                                    doc.value == dataInfoCustomer.id_district
                                ).label2
                              : null
                          } จังหวัด${
                            props.selectProvince1.length != 0 &&
                            dataInfoCustomer.id_province != ""
                              ? props.selectProvince1.find(
                                  (doc) =>
                                    doc.value == dataInfoCustomer.id_province
                                ).label2
                              : null
                          } ${dataInfoCustomer.postal_code}`}
                        </b>
                      </p>
                    ) : (
                      ""
                    )}
                    <p
                      className="m-1"
                      style={{ fontSize: "12.5px", color: "black" }}
                    >
                      เบอร์ติดต่อ/TEL NO. :&nbsp;
                      <b>
                        {dataInfoInvoice.type && dataInfoInvoice.type == "ABB"
                          ? dataInfoInvoice.customer_phone_no
                          : dataInfoCustomer.phone_no}
                      </b>
                    </p>
                    {dataInfoInvoice.type && dataInfoInvoice.type != "ABB" ? (
                      <p
                        className="m-1"
                        style={{ fontSize: "12.5px", color: "black" }}
                      >
                        {dataInfoCustomer.detail_type != "personal"
                          ? "เลขประจำตัวผู้เสียภาษีอากร/TAX ID. :"
                          : "เลขประจำตัวประชาชน/ID Card No. :"}
                        &nbsp;
                        <b>
                          {dataInfoCustomer.detail_type != "personal"
                            ? dataInfoCustomer.id_tax
                            : dataInfoCustomer.id_passport}
                        </b>
                      </p>
                    ) : (
                      ""
                    )}
                  </div>
                </Col>
                <Col xs={2}></Col>
                <Col xs={5}>
                  <div
                    className="p-1"
                    style={{
                      fontSize: "12.5px",
                      position: "static",
                      border: "1px solid black",
                    }}
                  >
                    <p
                      className="m-1"
                      style={{ fontSize: "12.5px", color: "black" }}
                    >
                      ใบวางบิลเลขที่/Invoice No. :&nbsp;
                      <b>{dataInfoInvoice.invoice_no}</b>
                    </p>
                    <p
                      className="m-1"
                      style={{ fontSize: "12.5px", color: "black" }}
                    >
                      วันที่วางบิล/Issued Date :&nbsp;
                      <b>{set_date(dataInfoInvoice.issue_date)}</b>
                    </p>
                    <p
                      className="m-1"
                      style={{ fontSize: "12.5px", color: "black" }}
                    >
                      สัญญาเลขที่/Contract No. :&nbsp;
                      <b>
                        {dataInfoCompany.bill.bank.prefix_ref2}
                        {dataInfoInvoice.contract_no}
                      </b>
                    </p>
                    <p
                      className="m-1"
                      style={{ fontSize: "12.5px", color: "black" }}
                    >
                      วันครบกำหนดชำระ/Due Date :&nbsp;
                      <b>{set_date(dataInfoInvoice.due_date)}</b>
                    </p>
                  </div>
                  <div
                    className="p-1 mt-2"
                    style={{
                      fontSize: "12.5px",
                      position: "static",
                      border: "1px solid black",
                      color: "black",
                    }}
                  >
                    รูปแบบการชำระเงิน/Payment Type. :&nbsp;
                    <b></b>
                    <br />
                    กำหนดชำระ/CREDIT TERM :&nbsp;
                    <b>
                      {set_time(dataInfoInvoice.due_date) -
                        set_time(dataInfoInvoice.issue_date) >
                      0
                        ? `${parseInt(
                            (set_time(dataInfoInvoice.due_date) -
                              set_time(dataInfoInvoice.issue_date)) /
                              (1000 * 60 * 60 * 24)
                          )} วัน/Day(s)`
                        : "วันนี้/Today"}
                    </b>
                    <br />
                    {dataInfoInvoice.purchase_order_no
                      ? "ใบสั่งซื้อเลขที่/Purchase Order No. : "
                      : null}
                    {dataInfoInvoice.purchase_order_no ? (
                      <b>{dataInfoInvoice.purchase_order_no}</b>
                    ) : null}
                  </div>
                </Col>
              </Row>
              <label style={{ color: "black" }} className="mt-3">
                ชื่อโปรเจค/Project Name : <b>{dataInfoInvoice.name_project}</b>
              </label>
              <table
                style={{
                  fontSize: "12.5px",
                  color: "black",
                  minWidth: "100%",
                }}
                className="mb-0"
              >
                <tbody className="text-center">
                  <tr>
                    <td
                      style={{
                        border: "1px solid black",
                        borderRight: 0,
                        borderBottom: 0,
                      }}
                      width="5%"
                    >
                      รายการ
                      <br />
                      No.
                    </td>
                    <td
                      style={{
                        border: "1px solid black",
                        borderRight: 0,
                        borderBottom: 0,
                      }}
                      width="15%"
                    >
                      ชื่อสินค้า
                      <br />
                      Product Name
                    </td>
                    <td
                      style={{
                        border: "1px solid black",
                        borderRight: 0,
                        borderBottom: 0,
                      }}
                      width="25%"
                    >
                      คำอธิบาย
                      <br />
                      Description
                    </td>
                    <td
                      style={{
                        border: "1px solid black",
                        borderRight: 0,
                        borderBottom: 0,
                      }}
                      width="5%"
                    >
                      จำนวน
                      <br />
                      Qty
                    </td>
                    <td
                      style={{
                        border: "1px solid black",
                        borderRight: 0,
                        borderBottom: 0,
                      }}
                      width="5%"
                    >
                      หน่วย
                      <br />
                      Unit
                    </td>
                    <td
                      style={{
                        border: "1px solid black",
                        borderRight: 0,
                        borderBottom: 0,
                      }}
                      width="15%"
                    >
                      ราคาต่อหน่วย
                      <br />
                      Price per Unit
                    </td>
                    <td
                      style={{
                        border: "1px solid black",
                        borderRight: 0,
                        borderBottom: 0,
                      }}
                      width="15%"
                    >
                      ส่วนลด
                      <br />
                      Discount
                    </td>
                    <td
                      style={{ border: "1px solid black", borderBottom: 0 }}
                      width="15%"
                    >
                      ราคา
                      <br />
                      Price
                    </td>
                  </tr>
                  {dataInfoInvoice.product_detail.map((doc, index) => {
                    return (
                      <tr>
                        <td
                          style={{ border: "1px solid black", borderRight: 0 }}
                          height="40px"
                        >
                          <b>{index + 1}</b>
                        </td>
                        <td
                          style={{ border: "1px solid black", borderRight: 0 }}
                        >
                          <b>{doc.name_product}</b>
                        </td>
                        <td
                          style={{ border: "1px solid black", borderRight: 0 }}
                        >
                          <b>
                            {doc.description}{" "}
                            {doc.contract_agreement
                              ? `(${set_date(
                                  doc.issue_date_product,
                                  "/"
                                )} - ${set_date(doc.due_date_product, "/")})`
                              : ""}
                          </b>
                        </td>
                        <td
                          style={{ border: "1px solid black", borderRight: 0 }}
                        >
                          <b>{doc.qty}</b>
                        </td>
                        <td
                          style={{ border: "1px solid black", borderRight: 0 }}
                        >
                          <b>{doc.unit}</b>
                        </td>
                        <td
                          style={{ border: "1px solid black", borderRight: 0 }}
                        >
                          <b>{numberWithCommas(doc.price_per_unit)}</b>
                        </td>
                        <td
                          style={{ border: "1px solid black", borderRight: 0 }}
                        >
                          <b>
                            {doc.discount_type != "Percentage"
                              ? doc.discount != ""
                                ? numberWithCommas(doc.discount)
                                : "0.00"
                              : doc.discount != ""
                              ? numberWithCommas(
                                  (doc.total * parseFloat(doc.discount)) / 100
                                )
                              : "0.00"}
                          </b>
                        </td>
                        <td style={{ border: "1px solid black" }}>
                          <b>{numberWithCommas(doc.total)}</b>
                        </td>
                      </tr>
                    );
                  })}
                  <tr>
                    <td
                      class="border-0"
                      style={{
                        border: 0,
                        textAlign: "left",
                      }}
                      colSpan="6"
                    >
                      <b> จำนวนเงินรวมทั้งสิ้น/Total :</b>{" "}
                      {/*THBText(parseFloat(dataInfoInvoice.total))*/}
                      {THBText(dataInfoInvoice.total)}
                    </td>
                    <td
                      class="border-0"
                      style={{ border: 0, textAlign: "right" }}
                    >
                      รวมเป็นเงิน/Subtotal :&nbsp;
                    </td>
                    <td
                      style={{ border: "1px solid black", borderTop: 0 }}
                      height="40px"
                    >
                      <b>{numberWithCommas(dataInfoInvoice.subtotal)}</b>
                    </td>
                  </tr>
                  <tr>
                    <td
                      class="border-0 align-top"
                      style={{
                        border: 0,
                        textAlign: "left",
                        whiteSpace: "pre-line",
                      }}
                      colSpan="6"
                      rowspan="3"
                    >
                      <b>หมายเหตุ/Remark(s)</b> : {dataInfoInvoice.remark}
                    </td>
                    <td
                      class="border-0"
                      style={{ border: 0, textAlign: "right" }}
                    >
                      ส่วนลด/Discount :&nbsp;
                    </td>
                    <td
                      style={{ border: "1px solid black", borderTop: 0 }}
                      height="40px"
                    >
                      <b>
                        {dataInfoInvoice.discount_type != "Percentage"
                          ? dataInfoInvoice.discount != ""
                            ? numberWithCommas(dataInfoInvoice.discount)
                            : "0.00"
                          : dataInfoInvoice.discount != ""
                          ? numberWithCommas(
                              (dataInfoInvoice.subtotal *
                                parseFloat(dataInfoInvoice.discount)) /
                                100
                            )
                          : "0.00"}
                      </b>
                    </td>
                  </tr>
                  <tr>
                    {/* <td
                      class="border-0"
                      style={{ border: 0, textAlign: "left" }}
                      colSpan="6"
                    ></td> */}
                    <td
                      class="border-0"
                      style={{ border: 0, textAlign: "right" }}
                    >
                      ภาษีมูลค่าเพิ่ม/VAT (7%) :&nbsp;
                    </td>
                    <td
                      style={{ border: "1px solid black", borderTop: 0 }}
                      height="40px"
                    >
                      <b>{numberWithCommas(dataInfoInvoice.vat)}</b>
                    </td>
                  </tr>
                  <tr>
                    {/* <td
                      class="border-0"
                      style={{ border: 0, textAlign: "left" }}
                      colSpan="6"
                    ></td> */}
                    <td
                      class="border-0"
                      style={{ border: 0, textAlign: "right" }}
                    >
                      จำนวนเงินรวมทั้งสิ้น/Total :&nbsp;
                    </td>
                    <td
                      style={{ border: "1px solid black", borderTop: 0 }}
                      height="40px"
                    >
                      <b>{numberWithCommas(dataInfoInvoice.total)}</b>
                    </td>
                  </tr>
                  <tr>
                    <td class="border-0" colSpan="7"></td>
                    <td class="border-0" style={{ verticalAlign: "top" }}>
                      <div
                        class="mt-1"
                        style={{ border: "1px solid black", borderTop: 0 }}
                      ></div>
                    </td>
                  </tr>
                  {dataInfoInvoice.idWithHoldingTax != "" ? (
                    <>
                      <tr>
                        <td class="border-0" colSpan="5"></td>
                        <td class="border-0" colSpan="3">
                          <div
                            class="mt-2 mb-2"
                            style={{ border: "1px dashed black", borderTop: 0 }}
                          ></div>
                        </td>
                      </tr>
                      <tr>
                        <td
                          class="border-0"
                          style={{ border: 0, textAlign: "left" }}
                          colSpan="5"
                        ></td>
                        <td
                          class="border-0"
                          colSpan="2"
                          style={{ border: 0, textAlign: "right" }}
                        >
                          ภาษีหัก ณ ที่จ่าย / Withholding Tax{" "}
                          {dataInfoInvoice.idWithHoldingTax != "" &&
                          props.widthholdingtax.length != 0
                            ? props.widthholdingtax.find(
                                (doc) =>
                                  doc.value == dataInfoInvoice.idWithHoldingTax
                              ).label
                            : null}{" "}
                          :&nbsp;
                        </td>
                        <td style={{ border: "1px solid black" }} height="40px">
                          <b>
                            {numberWithCommas(
                              dataInfoInvoice.holding_tax_price
                            )}
                          </b>
                        </td>
                      </tr>
                      <tr>
                        <td
                          class="border-0"
                          style={{ border: 0, textAlign: "left" }}
                          colSpan="5"
                        ></td>
                        <td
                          class="border-0"
                          colSpan="2"
                          style={{ border: 0, textAlign: "right" }}
                        >
                          ยอดชำระ / Payment Amount :&nbsp;
                        </td>
                        <td style={{ border: "1px solid black" }} height="40px">
                          <b>
                            {numberWithCommas(dataInfoInvoice.total_amount)}
                          </b>
                        </td>
                      </tr>
                      <tr>
                        <td class="border-0" colSpan="7"></td>
                        <td class="border-0" style={{ verticalAlign: "top" }}>
                          <div
                            class="mt-1"
                            style={{ border: "1px solid black", borderTop: 0 }}
                          ></div>
                        </td>
                      </tr>
                    </>
                  ) : null}
                </tbody>
              </table>
              <table
                style={{
                  fontSize: "12.5px",
                  color: "black",
                  minWidth: "100%",
                }}
                className="mb-0 mt-4"
              >
                <tbody className="text-center">
                  <tr>
                    <td
                      style={{
                        border: "1px solid black",
                        borderRight: 0,
                      }}
                      width="30%"
                    >
                      <div style={{ padding: "10px" }}>
                        <div style={{ textAlign: "left" }}>
                          {dataInfoCompany.name_company}
                        </div>
                        <div className="mt-2">
                          <Image
                            src={dataInfoCompany.authsignature_company_url}
                            style={{
                              width: "60px",
                              position: "absolute",
                              marginLeft: "-5%",
                            }}
                          />
                          <Image
                            src={dataInfoCompany.stamp_company_url}
                            style={{
                              width: "140px",
                              position: "absolute",
                              marginLeft: "2%",
                              marginTop: "-2%",
                            }}
                          />
                        </div>
                        <div
                          className="mt-5"
                          style={{
                            borderTop: "2px dotted  black",
                            padding: "5px",
                          }}
                        >
                          ผู้วางบิล
                        </div>
                      </div>
                    </td>
                    <td
                      style={{
                        border: "1px solid black",
                        borderRight: `${
                          dataInfoCompany.transfer.enable ? 0 : 1
                        }`,
                      }}
                      width="30%"
                    >
                      <div style={{ padding: "10px" }}>
                        <div style={{ textAlign: "left" }}>รับทราบ</div>
                        <div className="mt-2"></div>
                        <div
                          className="mt-5"
                          style={{
                            borderTop: "2px dotted  black",
                            padding: "5px",
                          }}
                        >
                          ผู้รับวางบิล
                        </div>
                      </div>
                    </td>
                    {dataInfoCompany.transfer.enable ? (
                      <td
                        style={{
                          border: "1px solid black",
                        }}
                        width="40%"
                      >
                        <div style={{ padding: "10px" }}>
                          <div style={{ textAlign: "left" }}>
                            สำหรับโอนเงิน{" "}
                            {props.bankPayment.length != 0 &&
                            dataInfoCompany.transfer.id_bank != ""
                              ? props.bankPayment.find(
                                  (doc) =>
                                    doc.id_bank ==
                                    dataInfoCompany.transfer.id_bank
                                ).name_bank_th
                              : null}{" "}
                            {dataInfoCompany.transfer.branch_name != ""
                              ? `สาขา ${dataInfoCompany.transfer.branch_name}`
                              : null}
                          </div>
                          <div className="mt-1">
                            <div
                              style={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "space-between",
                                flexWrap: "wrap",
                              }}
                            >
                              <div>
                                <Image
                                  src={
                                    props.bankPayment.length != 0 &&
                                    dataInfoCompany.transfer.id_bank != ""
                                      ? `data:image/png;base64,${
                                          props.bankPayment.find(
                                            (doc) =>
                                              doc.id_bank ==
                                              dataInfoCompany.transfer.id_bank
                                          ).bank_icon
                                        }`
                                      : null
                                  }
                                  height="80"
                                />
                              </div>
                              <div style={{ maxWidth: "200px" }}>
                                {dataInfoCompany.transfer.account_name}
                                <br />
                                {formatNumBank(
                                  dataInfoCompany.transfer.account_number
                                )}
                                <br />
                                {dataInfoCompany.transfer.account_type}
                              </div>
                              <div>
                                {dataInfoCompany.transfer.photo_upload.url !=
                                null ? (
                                  <Image
                                    src={
                                      dataInfoCompany.transfer.photo_upload.url
                                    }
                                    height="80"
                                  />
                                ) : null}
                              </div>
                            </div>
                          </div>
                        </div>
                      </td>
                    ) : null}
                  </tr>
                </tbody>
              </table>

              {/* ======================================== Line ====================================================== */}
              {dataInfoCompany.bill.enable ? (
                <div className="billPayPage">
                  <div style={{ minWidth: "900px" }}>
                    <div
                      style={{
                        borderTop: "2px dashed black",
                        marginTop: "1.5rem",
                      }}
                    ></div>

                    <Row className="mt-4">
                      <Col xs={5} className="pr-0">
                        <Col xs={9} className="pl-0">
                          <div style={{ marginBottom: "4rem", color: "black" }}>
                            <p
                              className="pl-4 pr-4 pb-1 pt-1 text-center"
                              style={{ border: "1px solid black" }}
                            >
                              สำหรับธนาคาร (For Bank Use Only)
                            </p>
                          </div>
                        </Col>
                        <p
                          style={{
                            marginTop: "25px",
                            fontSize: "12.5px",
                            whiteSpace: "nowrap",
                            color: "black",
                          }}
                        >
                          {dataInfoCompany.name_company_en.toUpperCase()}
                          <br />
                          {dataInfoCompany.name_company}
                          <br />
                          {`${dataInfoCompany.address_en}, ${
                            props.selectSubDistrict.length != 0 &&
                            dataInfoCompany.id_subdistrict != ""
                              ? props.selectSubDistrict.find(
                                  (doc) =>
                                    doc.value == dataInfoCompany.id_subdistrict
                                ).label1
                              : null
                          }, ${
                            props.selectDistrict.length != 0 &&
                            dataInfoCompany.id_district != ""
                              ? props.selectDistrict
                                  .find(
                                    (doc) =>
                                      doc.value == dataInfoCompany.id_district
                                  )
                                  .label1.split("Amphoe")[1]
                              : null
                          }, ${
                            props.selectProvince.length != 0 &&
                            dataInfoCompany.id_province != ""
                              ? props.selectProvince.find(
                                  (doc) =>
                                    doc.value == dataInfoCompany.id_province
                                ).label1
                              : null
                          }  Thailand ${dataInfoCompany.postal_code} `}
                          <br />
                          {`${dataInfoCompany.address} ตำบล${
                            props.selectSubDistrict.length != 0 &&
                            dataInfoCompany.id_subdistrict != ""
                              ? props.selectSubDistrict.find(
                                  (doc) =>
                                    doc.value == dataInfoCompany.id_subdistrict
                                ).label2
                              : null
                          } ${
                            props.selectDistrict.length != 0 &&
                            dataInfoCompany.id_district != ""
                              ? props.selectDistrict.find(
                                  (doc) =>
                                    doc.value == dataInfoCompany.id_district
                                ).label2
                              : null
                          } จังหวัด${
                            props.selectProvince.length != 0 &&
                            dataInfoCompany.id_province != ""
                              ? props.selectProvince.find(
                                  (doc) =>
                                    doc.value == dataInfoCompany.id_province
                                ).label2
                              : null
                          } ${dataInfoCompany.postal_code}`}
                          <br />
                          {`Tel : ${dataInfoCompany.phone_no} Fax : ${dataInfoCompany.fax_no}`}
                          <br />
                          {`เลขประจําตัวผู้เสียภาษีอากร : ${
                            dataInfoCompany.id_tax
                          } สาขาประกอบการ ${
                            dataInfoCompany.head_office
                              ? "สํานักงานใหญ่"
                              : dataInfoCompany.name_branch
                          }`}
                          <br />
                          {`TAX ID : ${dataInfoCompany.id_tax} Branch No. : ${
                            dataInfoCompany.head_office
                              ? "Head Office"
                              : dataInfoCompany.name_branch
                          }`}
                        </p>
                      </Col>
                      <Col xs={2} className="p-0"></Col>
                      <Col xs={5}>
                        <div className="text-right mr-4">
                          <Image
                            src={dataInfoCompany.icon_company_url}
                            style={{ maxWidth: "100%", maxHeight: "80px" }}
                          />
                          <Image
                            src={dataInfoCompany.textlogo_company_url}
                            style={{ maxWidth: "45%", maxHeight: "100px" }}
                          />
                        </div>
                        <div
                          className="p-1 mt-2"
                          style={{
                            fontSize: "12px",
                            position: "static",
                            border: "1px solid black",
                          }}
                        >
                          <p
                            className="m-1"
                            style={{ fontSize: "12.5px", color: "black" }}
                          >
                            วันที่ชำระ/Payment Date :&nbsp;
                          </p>
                          <p
                            className="m-1"
                            style={{ fontSize: "12.5px", color: "black" }}
                          >
                            ชื่อลูกค้า/Company Name :&nbsp;
                            <b>
                              {dataInfoCustomer.name_company != ""
                                ? dataInfoCustomer.name_company
                                : dataInfoCustomer.name_personal != ""
                                ? dataInfoCustomer.name_personal
                                : dataInfoCustomer.name_organization}
                            </b>
                          </p>
                          <p
                            className="m-1"
                            style={{ fontSize: "12.5px", color: "black" }}
                          >
                            Customer Code (Ref.1) :&nbsp;
                            <b>
                              {dataInfoCompany.bill.bank.prefix_ref1}
                              {dataInfoCustomer.customer_code}
                            </b>
                          </p>
                          <p
                            className="m-1"
                            style={{ fontSize: "12.5px", color: "black" }}
                          >
                            Contract No. (Ref.2) :&nbsp;
                            <b>
                              {dataInfoCompany.bill.bank.prefix_ref1}
                              {dataInfoInvoice.contract_no}
                            </b>
                          </p>
                        </div>
                      </Col>
                    </Row>
                    <table
                      style={{
                        border: 0,
                        fontSize: "12px",
                        color: "black",
                        minWidth: "100%",
                      }}
                    >
                      <label style={{ color: "black" }}>ชำระโดย</label>
                      <tbody className="text-center">
                        <tr width="100%">
                          <td
                            style={{
                              border: "1px solid black",
                              borderRight: 0,
                              borderBottom: 0,
                            }}
                            className="p-2"
                            width="120px"
                          >
                            เงินสด / CASH
                          </td>
                          <td
                            style={{
                              border: "1px solid black",
                              borderRight: 0,
                              borderBottom: 0,
                            }}
                            className="p-2"
                            width="15%"
                          >
                            เลขที่เช็ค / CHEQUE NO.
                          </td>
                          <td
                            style={{
                              border: "1px solid black",
                              borderRight: 0,
                              borderBottom: 0,
                            }}
                            className="p-2"
                            width="15%"
                          >
                            เช็คลงวันที่ / DATE
                          </td>
                          <td
                            style={{
                              border: "1px solid black",
                              borderRight: 0,
                              borderBottom: 0,
                            }}
                            className="p-2"
                            width="20%"
                          >
                            ชื่อธนาคาร / BANK NAME
                          </td>
                          <td
                            style={{
                              border: "1px solid black",
                              borderRight: 0,
                              borderBottom: 0,
                            }}
                            className="p-2"
                            width="20%"
                          >
                            สาขา / BRANCH
                          </td>
                          <td
                            style={{
                              border: "1px solid black",
                              borderBottom: 0,
                            }}
                            className="p-2"
                            width="200px"
                          >
                            จำนวนเงิน / AMOUNT
                          </td>
                        </tr>
                        <tr>
                          <td
                            style={{
                              border: "1px solid black",
                              borderRight: 0,
                              borderBottom: 0,
                            }}
                            className="p-2"
                            height="40px"
                          >
                            เช็ค / CHEQUE
                          </td>
                          <td
                            style={{
                              border: "1px solid black",
                              borderRight: 0,
                              borderBottom: 0,
                            }}
                            className="p-2"
                          ></td>
                          <td
                            style={{
                              border: "1px solid black",
                              borderRight: 0,
                              borderBottom: 0,
                            }}
                            className="p-2"
                          ></td>
                          <td
                            style={{
                              border: "1px solid black",
                              borderRight: 0,
                              borderBottom: 0,
                            }}
                            className="p-2"
                          ></td>
                          <td
                            style={{
                              border: "1px solid black",
                              borderRight: 0,
                              borderBottom: 0,
                            }}
                            className="p-2"
                          ></td>
                          <td
                            style={{
                              border: "1px solid black",
                              borderBottom: 0,
                            }}
                            className="p-2"
                          ></td>
                        </tr>
                        <tr>
                          <td
                            style={{ border: "1px solid black" }}
                            className="text-left pl-3"
                            colSpan="6"
                            height="40px"
                          >
                            จำนวนเงินเป็นตัวอักษร (บาท) / IN WORDS (THB)
                          </td>
                        </tr>
                      </tbody>
                    </table>
                    <div className="row">
                      <Col
                        xs={9}
                        style={{
                          display: "flex",
                          flexWrap: "wrap",
                          marginTop: "1rem",
                        }}
                      >
                        {dataInfoCompany.bill.bank.bank_detail.map((doc) => {
                          return (
                            <div
                              className="mr-1"
                              style={{
                                // marginTop: "2rem",
                                fontSize: "11px",
                                color: "black",
                                lineHeight: "100%",
                              }}
                            >
                              <img
                                src={
                                  props.bankPayment.length != 0 &&
                                  doc.id_bank_bill != ""
                                    ? `data:image/png;base64,${
                                        props.bankPayment.find(
                                          (doc1) =>
                                            doc1.id_bank == doc.id_bank_bill
                                        ).bank_icon_noBG
                                      }`
                                    : null
                                }
                                width="auto"
                                height="20"
                              />
                              &nbsp;
                              {props.bankPayment.length != 0 &&
                              doc.id_bank_bill != ""
                                ? props.bankPayment.find(
                                    (doc1) => doc1.id_bank == doc.id_bank_bill
                                  ).name_bank_th
                                : null}{" "}
                              เลขที่บัญชี {formatNumBank(doc.bank_num)}{" "}
                              (Comp.code : {doc.bank_comp_code})
                            </div>
                          );
                        })}
                      </Col>
                      <Col>
                        <table
                          border="1"
                          style={{
                            marginTop: "1rem",
                            fontSize: "12px",
                            color: "black",
                            minWidth: "100%",
                          }}
                        >
                          <tbody className="text-center">
                            <tr>
                              <td
                                style={{ border: "1px solid black" }}
                                width="300px"
                                height="30px"
                              >
                                เจ้าหน้าที่ธนาคารผู้รับเงิน
                              </td>
                            </tr>
                            <tr>
                              <td
                                style={{
                                  border: "1px solid black",
                                  borderTop: 0,
                                }}
                                height="40px"
                              ></td>
                            </tr>
                          </tbody>
                        </table>
                      </Col>
                    </div>
                    <p
                      style={{
                        marginTop: "1rem",
                        fontSize: "12px",
                        color: "black",
                      }}
                    >
                      ผู้นำฝาก (Name of deposit)
                      ………………………………………………………….(ตัวบรรจง) โทร (Tel
                      No.)………………………………………………………….
                      <br />
                      ท่านสามารถนำใบแจ้งชำระเงินนี้ไปชำระได้ที่เคาเตอร์ธนาคารตามที่ระบุไว้
                      ทุกสาขาทั่วประเทศ(โปรดรอรับหลักฐานการชำระเงินจากธนาคารและตรวจสอบความถูกต้องด้วยทุกครั้ง)
                    </p>
                  </div>

                  <br />
                  <div>
                    {dataInfoCompany.bill.lotus.check ? (
                      <div style={{ fontSize: "12px", color: "black" }}>
                        สำหรับ LOTUS
                      </div>
                    ) : null}
                    <div
                      style={{
                        display: "flex",
                        justifyContent: `${
                          dataInfoCompany.bill.lotus.check
                            ? "space-between"
                            : "flex-end"
                        }`,
                        alignItems: "center",
                      }}
                    >
                      {dataInfoCompany.bill.lotus.check ? (
                        <div>
                          <SVG
                            className="barCode"
                            src={dataInfoInvoice.barcode_tesco}
                            style={{ width: "350px", display: "none" }}
                          />
                          {/* <div
                            style={{
                              fontSize: "90%",
                              transform: "scale(0.8)",
                              marginTop: "-16px",
                              whiteSpace: "nowrap",
                            }}
                          >
                            <b>
                              {$('[class="barCode"]')
                                .find("title")
                                .first()
                                .html()}
                            </b>
                          </div>  */}

                          <div style={{ border: "1px solid black" }}>
                            <div style={{ transform: "scale(0.9)" }}>
                              <Barcode
                                value={$('[class="barCode"]')
                                  .find("title")
                                  .first()
                                  .html()}
                                height="42"
                                width="1"
                                fontSize="12px"
                              />
                            </div>
                          </div>
                        </div>
                      ) : null}
                      {dataInfoCompany.bill.bank.check ? (
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "flex-end",
                            alignItems: "center",
                          }}
                        >
                          <div>
                            <div style={{ transform: "scale(0.9)" }}>
                              <Barcode
                                value={`|${dataInfoCompany.id_tax}${
                                  dataInfoCompany.bill.bank.suffix_key
                                }\r${dataInfoCompany.bill.bank.prefix_ref1}${
                                  dataInfoCustomer.customer_code
                                }\r${dataInfoCompany.bill.bank.prefix_ref2}${
                                  dataInfoInvoice.contract_no
                                }\r${parseFloat(dataInfoInvoice.total_amount)
                                  .toFixed(2)
                                  .toString()
                                  .split(".")
                                  .join("")}`}
                                height="42"
                                width="1"
                                fontSize="12px"
                              />
                            </div>
                          </div>
                          <div>
                            <QRCode
                              value={`|${dataInfoCompany.id_tax}${
                                dataInfoCompany.bill.bank.suffix_key
                              }\r${dataInfoCompany.bill.bank.prefix_ref1}${
                                dataInfoCustomer.customer_code
                              }\r${dataInfoCompany.bill.bank.prefix_ref2}${
                                dataInfoInvoice.contract_no
                              }\r${parseFloat(dataInfoInvoice.total_amount)
                                .toFixed(2)
                                .toString()
                                .split(".")
                                .join("")}`}
                              className="ml-5 mr-3"
                              style={{ height: "80px", width: "auto" }}
                            />
                          </div>
                        </div>
                      ) : null}
                    </div>
                  </div>
                </div>
              ) : null}
              <br />
            </div>
            {/* <div className="policy_page">
              {PolicyPage({
                ...props,
                policy_type: "terms_conditions",
                data: dataInfoCompany,
                text: { th: "เงื่อนไขการให้บริการ", en: "TERMS & CONDITIONS" },
                content: {
                  text: dataInfoCustomer.terms_conditions,
                  date: dataInfoCustomer.terms_conditions_issue_date,
                },
              })}
            </div>
            <div className="policy_page">
              {PolicyPage({
                ...props,
                policy_type: "privacy",
                data: dataInfoCompany,
                text: { th: "นโยบายความเป็นส่วนตัว", en: "PRIVACY POLICY" },
                content: {
                  text: dataInfoCustomer.privacy_policy,
                  date: dataInfoCustomer.privacy_policy_issue_date,
                },
              })}
            </div>
            <div className="policy_page">
              {PolicyPage({
                ...props,
                policy_type: "refundable",
                data: dataInfoCompany,
                text: { th: "นโยบายการคืนเงิน", en: "REFUNDABLE POLICY" },
                content: {
                  text: dataInfoCustomer.refundable_policy,
                  date: dataInfoCustomer.refundable_policy_issue_date,
                },
              })}
            </div> */}
          </Card.Body>
        </Card>
        <Form className="mt-3">
          <Form.Group>
            <Row
              className="align-items-center"
              style={{
                display: "flex",
                alignItems: "center",
                padding: "10px",
              }}
            >
              <Col xs={1} style={{ display: "flex", justifyContent: "center" }}>
                {dataInfoInvoice.status == "Pending" ? (
                  <>
                    <Form.Check
                      type="checkbox"
                      checked={agreePay}
                      onChange={() => setagreePay(!agreePay)}
                      style={{
      
                        display: "flex",
                        alignItems: "center",
                      }}
                    />
                  </>
                ) : (
                  <>
                    <Form.Check
                      type="checkbox"
                      checked={true}
                      disabled={true}
                      style={{
                        display: "flex",
                        alignItems: "center",
                      }}
                    />
                  </>
                )}
              </Col>
              <Col style={{ paddingLeft: "0" }}>
                <Form.Label
                  style={{
                    margin: "0",
                    color: "#003581",
                    fontSize: "14px",
                    lineHeight: "1.5",
                  }}
                >
                  I agree to the{" "}
                  <span
                    style={{
                      color: "#D33D4C",
                      textDecoration: "underline",
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      setpolicy_subject("Terms And Conditions");
                      setpolicy_content({
                        text: dataInfoCustomer.terms_conditions,
                        date: dataInfoCustomer.terms_conditions_issue_date,
                      });
                      setpolicy_text({
                        th: "เงื่อนไขการให้บริการ",
                        en: "TERMS & CONDITIONS",
                      });
                      setmodalShowPolicy(true);
                    }}
                  >
                    terms and conditions
                  </span>
                  , the{" "}
                  <span
                    style={{
                      color: "#D33D4C",
                      textDecoration: "underline",
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      setpolicy_subject("Privacy Policy");
                      setpolicy_content({
                        text: dataInfoCustomer.privacy_policy,
                        date: dataInfoCustomer.privacy_policy_issue_date,
                      });
                      setpolicy_text({
                        th: "นโยบายความเป็นส่วนตัว",
                        en: "PRIVACY POLICY",
                      });
                      setmodalShowPolicy(true);
                    }}
                  >
                    privacy policy
                  </span>
                  , and the{" "}
                  <span
                    style={{
                      color: "#D33D4C",
                      textDecoration: "underline",
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      setpolicy_subject("Refundable Policy");
                      setpolicy_content({
                        text: dataInfoCustomer.refundable_policy,
                        date: dataInfoCustomer.refundable_policy_issue_date,
                      });
                      setpolicy_text({
                        th: "นโยบายการคืนเงิน",
                        en: "REFUNDABLE POLICY",
                      });
                      setmodalShowPolicy(true);
                    }}
                  >
                    refundable policy
                  </span>
                  .
                </Form.Label>
              </Col>
            </Row>
          </Form.Group>
        </Form>
        <div style={{ display: "flex", textAlign: "center" }}>
          <ReactToPrint
            trigger={() => (
              <Button
                className="mr-2"
                name="btn_close"
                variant="link"
                onClick={0}
                style={{
                  color: "#003581",
                  border: "1px solid #D33D4C",
                  borderRadius: "10px",
                  width: "150px",
                  textDecoration: "none",
                }}
              >
                Print
              </Button>
            )}
            content={() => ref.current}
          />
          {(dataInfoCompany.cash.check ||
            dataInfoCompany.credit.enable ||
            dataInfoCompany.paypal.enable ||
            dataInfoCompany.bill.enable ||
            dataInfoCompany.transfer.enable) &&
          dataInfoInvoice.status == "Pending" ? (
            <Button
              name="btn_save"
              variant="danger"
              onClick={() => setmodalShowPayment(true)}
              style={{ borderRadius: "10px", width: "150px" }}
              disabled={!agreePay}
            >
              Agree And Pay
            </Button>
          ) : null}
        </div>
      </StyledWrapper>
      <ModalPolicy
        show={modalShowPolicy}
        onHide={() => setmodalShowPolicy(false)}
        subject={policy_subject}
        text={policy_text}
        content={policy_content}
        company={dataInfoCompany}
      />
      <ModalPayment
        show={modalShowPayment}
        onHide={() => setmodalShowPayment(false)}
        company={dataInfoCompany}
      />
      <SpinnerLoading
        modal={
          props.loading ||
          props.customerINvoice.length == 0 ||
          props.companyPkindev.length == 0
        }
      />
    </>
  );
};

const mapStateToProps = ({ settings, customerInvoice }) => {
  const {
    loading,
    selectProvince,
    selectDistrict,
    selectSubDistrict,
    selectProvince1,
    selectDistrict1,
    selectSubDistrict1,
    companyPkindev,
  } = settings;
  const { customerINvoice, nameTitle, widthholdingtax, bankPayment } =
    customerInvoice;
  // console.log(" mapStateToProps ", settings);
  // console.log(" mapStateToProps ", customerInvoice);
  return {
    loading,
    selectProvince,
    selectDistrict,
    selectSubDistrict,
    selectProvince1,
    selectDistrict1,
    selectSubDistrict1,
    companyPkindev,
    customerINvoice,
    nameTitle,
    widthholdingtax,
    bankPayment,
  };
};

export default connect(mapStateToProps, {
  provinceAddress,
  districtAddress,
  subdistrictAddress,
  getProvince1,
  getDistrict1,
  getSubdistrict1,
  getCompanyPkindev,
  getCustomerInvoice,
  getNameTitle,
  getWidthHoldingTax,
  getBankPayment,
})(BillingNote);
